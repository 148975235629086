import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Wrapper from './Home_styles';
import {
    AiOutlineCalculator,
    AiOutlineDesktop,
    AiOutlineMail,
} from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import { HiOutlineMail, HiOutlineCalendar } from 'react-icons/hi';
import supportImg from '../../assets/images/support.png';
import techImg from '../../assets/images/tech.svg';
import chatImg from '../../assets/images/chat.svg';
import superWomanImg from '../../assets/images/super-woman.svg';
import businessImg from '../../assets/images/business.svg';

const metaTitle =
    'Dritte Säule - die für Sie maßgeschneiderte private Altersvorsorge';
const metaDescription =
    'Die dritte Säule ist ein Vorsorgeplan, mit dem Sie Ihr Geld anlegen können, ohne auf den Versicherungsschutz inklusive Invalidenrente, Kapital im Todesfall, Prämienbefreiung bei Krankheit und Unfall verzichten zu müssen. Stellen Sie jetzt Ihre Anfrage: Maßgeschneidert auf Ihre Bedürfnisse.';

const fusionText = ['Einfach', 'Praktisch', '100% Online'];

const Home = () => {
    const fusionTextRef = useRef(null);
    const [currentFusionTextIndex, setCurrentFusionTextIndex] = useState(0);
    const [effectTriggerer, setEffectTriggerer] = useState(Date.now());

    useEffect(() => {
        const el = fusionTextRef.current;
        let timeout;
        if (el.classList.contains('shrink')) {
            el.classList.remove('shrink');
            timeout = setTimeout(() => {
                setEffectTriggerer(Date.now());
            }, 3000);
        } else {
            el.classList.add('shrink');
            timeout = setTimeout(() => {
                const newIndex =
                    currentFusionTextIndex >= fusionText.length - 1
                        ? 0
                        : currentFusionTextIndex + 1;

                setCurrentFusionTextIndex(newIndex);
            }, 1000);
        }

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFusionTextIndex, effectTriggerer]);

    return (
        <Wrapper>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
            </Helmet>
            <div className="container">
                <section className="hero-section">
                    <div className="content">
                        <h1>Gestalten deine dritte Säule</h1>
                        <h2 style={{ fontWeight: 'normal' }}>
                            ohne viel bla bla
                        </h2>
                        <p className="hero-text fusion-text">
                            <span ref={fusionTextRef}>
                                {fusionText[currentFusionTextIndex]}
                            </span>
                        </p>
                        <Link className="btn btn-hero btn-block" to="/pricing">
                            Online-Konfigurator
                        </Link>
                        <Link
                            className="btn btn-outline btn-hero btn-block"
                            to="/contact"
                            style={{ textTransform: 'none' }}
                        >
                            Beratung anfragen
                        </Link>
                    </div>
                    <div className="image">
                        <img src={supportImg} className="img" alt="Support" />
                    </div>
                </section>
                <hr />
                <section>
                    <div className="content">
                        <h2>Maßgeschneidert</h2>
                        <p>
                            Wüsstest du, dass in der Schweiz mehr als 240 Banken
                            und etwa 200 Versicherungsgesellschaften existieren?
                            3-säule.info analysiert deine Anfrage und führt dich
                            zur besten Lösung auf den Markt.
                        </p>
                    </div>
                    <div className="image">
                        <img src={techImg} className="img" alt="Technology" />
                    </div>
                </section>
                <section className="reverse-section">
                    <div className="content">
                        <h2>100% Online</h2>
                        <p>
                            Von der Suche bis zum Abschluss! Ohne Termine
                            vereinbaren müssen, ohne Einhaltung von Bürozeiten.
                            Du bleibst einfach zu Hause - wir kümmern uns um
                            alles.
                        </p>
                    </div>
                    <div className="image">
                        <img src={chatImg} className="img" alt="Chatting" />
                    </div>
                </section>
                <section>
                    <div className="content">
                        <h2>Hochmodern</h2>
                        <p>
                            Wähl deinen Lieblingskanal: Telefon, E-Mail oder
                            schreib uns über WhatsApp. Wir bieten auch
                            Online-Videoberatungen mit unseren Vorsorgeexperten!
                        </p>
                    </div>
                    <div className="image">
                        <img
                            src={superWomanImg}
                            className="img"
                            alt="Super Woman"
                        />
                    </div>
                </section>
                <section className="reverse-section">
                    <div className="content">
                        <h2>Self Academy</h2>
                        <p>
                            Möchtest du mehr erfahren, aber nicht mit
                            Versicherungsbrokern zu tun zu haben? Wir wissen es,
                            deshalb haben wir eine eigene Rubrik{' '}
                            <Link
                                to="/info"
                                style={{ color: 'var(--primary-500)' }}
                            >
                                (Info)
                            </Link>{' '}
                            eingerichtet, in der du alle wichtigen Informationen
                            zur Altersvorsorge und zur dritten Säule findest.
                        </p>
                    </div>
                    <div className="image">
                        <img
                            src={businessImg}
                            className="img"
                            alt="Business"
                            loading="lazy"
                            style={{ maxHeight: 400, objectFit: 'fill' }}
                        />
                    </div>
                </section>
            </div>
            <div className="banner banner-data">
                <div className="container">
                    <div className="item">
                        <p className="data">7300</p>
                        <small>Kunden</small>
                    </div>
                    <div className="item">
                        <p className="data">8900</p>
                        <small>Empfohlene Lösungen </small>
                    </div>
                    <div className="item">
                        <p className="data">4.9/5</p>
                        <small>Google-Bewertung</small>
                    </div>
                    <div className="item">
                        <p className="data">2018</p>
                        <small>Gründungsjahr</small>
                    </div>
                </div>
            </div>
            <div className="container">
                <section className="its-simple">
                    <h1>Wie funktioniert es?</h1>
                    <div>
                        <div className="item">
                            <AiOutlineCalculator />
                            <p>
                                Personalisier und konfigurier deinen Antrag
                                online.
                            </p>
                        </div>
                        <div className="item">
                            <AiOutlineMail />
                            <p>
                                Bekomm die beste Lösung per E-Mail innert 48
                                Stunden.
                            </p>
                        </div>
                        <div className="item">
                            <AiOutlineDesktop />
                            <p>Wähl und fahr mit dem Online-Abschluss fort.</p>
                        </div>
                    </div>
                    <Link className="btn btn-hero" to="/pricing">
                        Online-Konfigurator
                    </Link>
                </section>
                <hr />
                <section className="need-help">
                    <h3>Wie können wir dir helfen?</h3>
                    <div>
                        <div className="item">
                            <p>Schreib uns eine E-Mail</p>
                            <a
                                aria-label="Schreib uns"
                                href="mailto:info@elefin.ch"
                                rel="noopener noreferrer"
                                className="btn btn-outline btn-hero"
                            >
                                <HiOutlineMail />
                            </a>
                        </div>
                        <div className="item">
                            <p>Chat mit uns über WhatsApp</p>
                            <a
                                aria-label="Chat mit uns"
                                href="https://wa.me/+41918808787"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline btn-hero"
                            >
                                <FaWhatsapp />
                            </a>
                        </div>
                        <div className="item">
                            <p>Buche einen Termin</p>
                            <a
                                aria-label="Buche einen Termin"
                                href="https://calendly.com/terzopilastro"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline btn-hero"
                            >
                                <HiOutlineCalendar />
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </Wrapper>
    );
};

export default Home;
