export const IT_DOMAIN = 'https://www.terzopilastro.info';
export const DE_DOMAIN = 'https://www.3-säule.info';

export const faqItems = [
  {
    id: 1,
    title: 'Wie lange dauert es, das Formular auszufüllen?',
    html: '<p>Das Ausfüllen des Formulars dauert ca. 5-10 Minuten.</p>',
  },
  {
    id: 2,
    title: 'Ist meine Online-Anfrage verbindlich?',
    html: '<p>Nein, sobald du das Formular ausgefüllt hast, erhältst du innerhalb von 48 Stunden ein oder mehrere unverbindliche Angebote.</p>',
  },
  {
    id: 3,
    title:
      'Ich habe bereits eine dritte Säule, kann ich eine Bewertung beantragen?',
    html: '<p>Natürlich kannst du das. Am Ende des Formulars kannst du im Feld «Bemerkungen» angeben, dass du bereits einen aktiven Vertrag hast. Du wirst dann um eine Kopie der Police gebeten, um eine Bewertung/Vergleich vornehmen zu können.</p>',
  },
  {
    id: 4,
    title: 'Ich bin ein Grenzgänger, kann ich eine dritte Säule abschließen?',
    html: '<p>Ja, du kannst auch eine dritte Säule abschließen, wenn du im Ausland lebst.<br/>Du muss jedoch ein Arbeitsverhältnis in der Schweiz haben (Aufenthaltserlaubnis G).</p>',
  },
  {
    id: 5,
    title:
      'Ist die Suche mit Kosten verbunden? Wie wird 3-säule.info finanziert?',
    html: '<p>Der Dienst 3-säule.info ist für den Nutzer kostenlos und wird direkt von den Partnern finanziert.</p>',
  },
];

export const PUBLIC_POSTS_PER_PAGE = 3;
