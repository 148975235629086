import { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import { marked } from 'marked';
import { FiChevronLeft } from 'react-icons/fi';
import { Loading } from '../../../components';
import { decodeHtml, formatTimestampToFullDate } from '../../../utils/utils';
import { useAppContext } from '../../../context/appContext';
import Wrapper from './SinglePost_styles';

const SinglePost = () => {
  const { slug } = useParams();

  const { appLoading, singlePost, getSinglePost } = useAppContext();

  useLayoutEffect(() => {
    getSinglePost(slug);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper className="container">
      {appLoading ? (
        <Loading center />
      ) : (
        <>
          <Helmet>
            <title>{`Dritte Säule - ${singlePost?.title}`}</title>
            <meta name="description" content={singlePost?.subtitle} />
            <meta
              property="og:title"
              content={`Dritte Säule - ${singlePost?.title}`}
            />
            <meta property="og:description" content={singlePost?.subtitle} />
          </Helmet>
          <p className="breadcrumb">
            {singlePost?.createdAt &&
              formatTimestampToFullDate(singlePost.createdAt)}
          </p>
          <div className="markdown-container">
            <h1 className="post-title">{singlePost?.title}</h1>
            <img
              className="img post-image"
              src={singlePost?.posterPath}
              alt="Poster"
            />
            <div className="post-content">
              <p>
                <strong>{singlePost?.subtitle}</strong>
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked.parse(decodeHtml(singlePost?.content) || ''),
                }}
              ></div>
            </div>
          </div>
          <Link to="/info" className="btn go-back">
            <FiChevronLeft /> Kehre zur Info zurück
          </Link>
        </>
      )}
    </Wrapper>
  );
};

export default SinglePost;
