import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp, FiYoutube } from 'react-icons/fi';
import { RiGlobalLine } from 'react-icons/ri';
import { HiOutlineMail } from 'react-icons/hi';
import { IT_DOMAIN } from '../../utils/constants';
import Wrapper from './Footer_styles';

const Footer = () => {
  const { pathname } = useLocation();

  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  return (
    <Wrapper>
      <div className="container">
        <ul className="footer-links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/pricing">Konfigurator</Link>
          </li>

          <li>
            <Link to="/info">Info</Link>
          </li>
          <li>
            <Link to="/contact">Kontakt</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li className="social-icons">
            <a
              aria-label="Email"
              href="mailto:info@elefin.ch"
              rel="noopener noreferrer"
            >
              <HiOutlineMail />
            </a>
            <a
              aria-label="WhatsApp"
              href="https://wa.me/+41918808787"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp />
            </a>
            <a
              aria-label="YouTube"
              href="https://www.google.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiYoutube />
            </a>
          </li>
        </ul>

        <hr />

        <div className="copyright-container">
          <div className="copyright">
            <small>© {new Date().getFullYear()} elefin</small>
          </div>

          <div
            className={
              isLanguageMenuOpen ? 'languages-list is-open' : 'languages-list'
            }
          >
            <button
              type="button"
              onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
            >
              <RiGlobalLine />
              <span>DE</span>
              {isLanguageMenuOpen ? <FiChevronDown /> : <FiChevronUp />}
            </button>
            <ul>
              <li>
                <a href={`${IT_DOMAIN}${pathname}`}>IT</a>
              </li>
              <li>
                <Link to="#">DE</Link>
              </li>
              <li>
                <Link to="#">FR</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
