import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import { faqItems } from '../../utils/constants';
import Wrapper from './Faq_styles';

const metaTitle =
  'Dritte Säule - die am häufigsten gestellten Fragen, die Sie sich selbst stellen können';
const metaDescription =
  'Sie sind sich immer noch nicht sicher, welchen Plan Sie wählen sollen? Hier finden Sie die Antworten auf die am häufigsten gestellten Fragen, die uns im Gespräch mit unseren Kunden begegnet sind. Denken Sie jedoch daran, dass Sie uns jederzeit kontaktieren können, um mit uns zu sprechen und etwaige Bedenken auszuräumen.';

const Contact = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  return (
    <Wrapper>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>
      <div className="container">
        <section className="hero-section">
          <h1>FAQ</h1>
          <p className="hero-text">
            Hier finden Sie die Antworten auf die am häufigsten gestellten
            Fragen.
          </p>
        </section>

        <section>
          <div className="accordion-container">
            {faqItems.map(({ id, title, html }) => (
              <div key={id} className="item">
                <button
                  type="button"
                  onClick={() =>
                    setOpenAccordion(openAccordion === id ? null : id)
                  }
                >
                  {openAccordion === id ? <BiMinusCircle /> : <BiPlusCircle />}
                  <h5>{title}</h5>
                </button>
                <div
                  className={
                    openAccordion === id ? 'accordion open' : 'accordion'
                  }
                >
                  <div dangerouslySetInnerHTML={{ __html: html }}></div>
                </div>
              </div>
            ))}
          </div>

          <div className="btn-container">
            <Link
              className="btn btn-outline btn-hero"
              to="/contact"
              style={{ textTransform: 'none' }}
            >
              Keine Antwort gefunden?
            </Link>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Contact;
