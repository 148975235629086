import { useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Loading, PostCard } from '../../../components';
import { PUBLIC_POSTS_PER_PAGE } from '../../../utils/constants';
import { useAppContext } from '../../../context/appContext';
import Wrapper from './BlogPosts_styles';

const metaTitle = 'Dritte Säule - Möchten Sie mehr erfahren?';
const metaDescription =
  'Zweifeln Sie an den Vorteilen der dritte Säule? Hier finden Sie eine große Sammlung von Finanz- und Wirtschaftsartikeln, die Ihnen auf einfache und umfassende Weise erklären, wie Sie den für Sie am besten geeigneten Ansparplan auswählen. Viel Spaß beim Lesen!';

const BlogPosts = () => {
  const {
    appLoading,
    posts: { categories, featuredPost, data: restOfPosts, pages },
    getPosts,
  } = useAppContext();

  const initialQueryValues = {
    search: '',
    category: 'all',
  };

  const [queryValues, setQueryValues] = useState(initialQueryValues);
  const [currentPage, setCurrentPage] = useState(1);

  const handleQueryValuesChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setQueryValues({ ...queryValues, [name]: value });
  };

  const submitQuery = e => {
    if (e) e.preventDefault();

    setCurrentPage(1);
    getPosts(queryValues);
  };

  const loadMore = e => {
    setCurrentPage(currentPage + 1);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    submitQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>
      <div className="container">
        <section className="hero-section">
          <h1>Info</h1>
          <p className="hero-text">
            Sie wollen sich informieren, aber nicht mit Versicherungsagenten
            streiten? Sie sind an der richtigen Stelle!
            <br />
            <br />
            Im Info finden Sie viele Informationen und Aktuelles rund um den
            Bereich Vorsorge und 3-Säule!
          </p>
        </section>

        <form className="search-container" onSubmit={submitQuery}>
          <div className="form-row">
            <label className="form-label" htmlFor="search">
              Suchen
            </label>
            <input
              type="search"
              className="form-input"
              id="search"
              name="search"
              onChange={handleQueryValuesChange}
              value={queryValues.search}
            />
          </div>
          <div className="form-row">
            <label className="form-label" htmlFor="category">
              Kategorie
            </label>
            <select
              className="form-select"
              name="category"
              id="category"
              onChange={handleQueryValuesChange}
              value={queryValues.category}
            >
              <option value="all">Alles</option>
              {categories.map((category, i) => (
                <option key={i} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <button type="submit" className="btn" disabled={appLoading}>
            Forschung
          </button>
        </form>

        {appLoading ? (
          <Loading center />
        ) : (
          <>
            <section className="posts-container">
              {featuredPost && <PostCard {...featuredPost} />}
              {restOfPosts
                .slice(0, currentPage * PUBLIC_POSTS_PER_PAGE)
                .map(post => (
                  <PostCard key={post._id} {...post} />
                ))}
            </section>
            {currentPage < pages && (
              <section className="btn-container">
                <button
                  type="button"
                  className="btn btn-hero"
                  onClick={loadMore}
                >
                  Andere Artikel
                </button>
              </section>
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default BlogPosts;
