import styled from 'styled-components';

const Wrapper = styled.main`
  color: var(--grey-400);

  section {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    column-gap: 4rem;
    row-gap: 4rem;
    padding: 3rem 0;

    .image {
      padding: 0 2rem;

      .img {
        margin: 0 auto;
        max-width: 400px;
      }
    }
  }

  .hero-section {
    margin-bottom: 2rem;
    row-gap: 5rem;
    .content {
      a:not(:last-of-type) {
        margin: 1rem 0;
      }
    }
    .hero-text {
      font-size: 1.55rem !important;
    }
    .image {
      padding: 0;

      .img {
        max-width: none;
      }
    }
  }

  .banner-data > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 2rem;

    .item {
      text-align: center;
      color: var(--primary-500);
      p.data {
        font-size: 1.75rem;
        margin: 0;
        margin-bottom: 0.25rem;
        font-weight: bold;
      }
      small {
        line-height: 1.1;
        display: block;
      }
    }
  }

  .its-simple {
    display: block;
    margin-bottom: 2rem;
    text-align: center;

    & > div {
      display: grid;
      align-items: center;
      padding: 2.5rem 0;
      gap: 2rem;

      p {
        margin: 0;
      }

      svg {
        font-size: 3rem;
        margin: 0 auto;
      }
    }

    a {
      margin-top: 2rem;
      width: 100%;
    }
  }

  .be-happy {
    display: block;
  }

  .need-help {
    display: block;
    text-align: center;

    & > div {
      padding-top: 1rem;
      display: inline-grid;
      align-items: center;
      row-gap: 2rem;
      column-gap: 3rem;

      .item {
        p {
          letter-spacing: var(--letterSpacing);
          font-weight: bold;
          color: var(--primary-500);
          font-size: 1.25rem;
          margin: 0;
        }

        a {
          letter-spacing: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0.75rem auto;
          text-transform: none;
          max-width: 120px;

          svg {
            font-size: 1.5rem;
            /* margin-right: 0.75rem; */
          }
        }

        small {
          display: block;
          font-size: var(--extra-small-text);
        }
      }
    }
  }

  .fusion-text {
    display: inline-block;

    span {
      position: relative;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      padding-right: 0.5rem;
      transition: all 1s ease-in-out;

      &.shrink {
        padding-right: 0;
        width: 1px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        width: 2px;
        height: 100%;
        background-color: var(--grey-400);
        animation: caret 0.5s step-end infinite;
      }
    }
  }

  @keyframes caret {
    from,
    to {
      background-color: transparent;
    }
    50% {
      background-color: var(--grey-400);
    }
  }

  @media screen and (min-width: 768px) {
    p {
      font-size: 1.15rem;
    }
    section {
      padding: 5rem 0;
      grid-template-columns: 340px 1fr;

      &.hero-section {
        grid-template-columns: 360px 1fr;
      }

      &.reverse-section {
        grid-template-columns: 1fr 340px;
        .content {
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 2;
          grid-column-end: 3;
        }
        .image {
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }
    }
    .banner-data > div {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      row-gap: 0;

      .item {
        p.data {
          font-size: 2.5rem;
        }
        small {
          font-size: 1rem;
        }
      }
    }
    .its-simple {
      & > div {
        grid-template-columns: 1fr 1fr 1fr;
      }
      a {
        width: auto;
      }
    }
    .need-help {
      & > div {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .its-simple > div {
      .item {
        display: flex;
        align-items: center;

        p {
          flex: 1;
        }

        svg {
          margin-right: 1rem;
        }
      }
    }
  }
`;

export default Wrapper;
