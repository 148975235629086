import { Helmet } from 'react-helmet';
import finishLine from '../../assets/images/finish-line.svg';
import Wrapper from './ThankYou_styles';

const metaTitle = 'Dritte Säule - Thank You';

const ThankYou = () => {
    return (
        <Wrapper className="container">
            <Helmet>
                <title>{metaTitle}</title>
                <meta property="og:title" content={metaTitle} />
                <meta name="robots" content="noindex" />
            </Helmet>
            <section className="full-width-section">
                <h3>
                    Vielen Dank, dass Sie sich für Dritte Säule entschieden
                    haben!
                </h3>
                <p>
                    Innerhalb von 48h erhalten Sie per E-Mail die für Sie
                    ausgewählten besten Lösungen auf dem Markt!
                </p>
                <img className="img" src={finishLine} alt="Finish Line" />
            </section>
        </Wrapper>
    );
};
export default ThankYou;
