import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import savings from '../../assets/images/savings.svg';
import performance from '../../assets/images/performance.svg';
import { FiInfo } from 'react-icons/fi';
import { CgCloseO } from 'react-icons/cg';
import {
  getFirstOfMonthDateString,
  validateUserInput,
  linearScrollToBottom,
} from '../../utils/utils';
import Wrapper from './Pricing_styles';
import { useAppContext } from '../../context/appContext';

const metaTitle = 'Dritte Säule - Berechnen Sie Ihren Sparplan online';
const metaDescription =
  'Passen Sie Ihren Sparplan mit unserem Online-Konfigurator an. Es sind nur wenige Schritte erforderlich, um die besten Angebote auf dem Markt entsprechend Ihren Bedürfnissen zu erhalten. Sie möchten Ihre derzeitige dritte Säule evaluieren? Senden Sie es uns für einen kostenlosen Vergleich.';

const initialValues = {
  newOrExisting: '',
  currentCompany: '',
  currentReward: '',
  genre: '',
  smoker: '',
  frontier: '',
  profession: '',
  born: '',
  height: '',
  weight: '',
  usPerson: '',
  solution: '',
  performance: {
    exempt: 'Prämienbefreiung',
    death: '',
    maturity: '',
  },
  contact: 'Ja',
  importancy: '',
  insuranceDate: '',
  paymentFrequency: '',
  reward: '',
  customReward: '',
  email: '',
  phone: '',
  fullName: '',
  address: '',
  nationality: '',
  postalCode: '',
  via: '',
  permit: '-',
  remarks: '',
};

const initialErrors = {
  currentCompany: '',
  currentReward: '',
  profession: '',
  born: '',
  height: '',
  weight: '',
  customReward: '',
  email: '',
  phone: '',
  fullName: '',
  nationality: '',
  postalCode: '',
  via: '',
};

const Pricing = () => {
  const { sendPricingForm } = useAppContext();

  const inputsRef = useRef([]);

  const [values, setValues] = useState(initialValues);
  const [currentStep, setCurrentStep] = useState(0);
  const [openInfoBtn, setOpenInfoBtn] = useState('');

  const [errors, setErrors] = useState(initialErrors);
  const [isError, setIsError] = useState(false);

  const [availableInsuranceDates, setAvailableInsuranceDates] = useState([]);

  const getAvailableInsuranceDates = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const dates = Array.from({ length: 2 }).map((_, i) => {
      return getFirstOfMonthDateString(currentYear, currentMonth + i + 1);
    });

    setAvailableInsuranceDates(dates);
    setValues({ ...values, insuranceDate: dates[0] });
  };

  const clearCustomRewardError = e => {
    setErrors({ ...errors, customReward: '' });
  };

  const handleValuesChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (e.target.type === 'checkbox') {
      const id = e.target.id;
      return setValues({
        ...values,
        [name]: {
          ...values[name],
          [id]: e.target.checked ? e.target.value : '',
        },
      });
    }

    let newValues = { ...values, [name]: value };

    if (name === 'solution' && value === 'Ertrag') {
      newValues.performance.maturity = '';
    }

    setValues(newValues);
  };

  const validateInput = e => {
    const name = e.target.name;
    const value = e.target.value;

    const error = validateUserInput(name, value);

    setErrors({ ...errors, [name]: error });
  };

  const validateAndChangeStep = step => {
    let e = { ...errors };
    let flag = false;
    for (const input of inputsRef.current) {
      if (input && !input.value) {
        flag = true;
        e[input.name] = 'Geben Sie einen Wert ein.';
      }
    }

    if (isError || flag) {
      return setErrors(e);
    }

    changeStep(step);
  };

  const changeStep = step => {
    if (step !== -1 && step <= currentStep) return;

    setCurrentStep(step);
  };

  useEffect(() => {
    if (currentStep === -1) return;
    if (values.insuranceDate && values.paymentFrequency && values.reward) {
      if (values.reward !== 'custom' || values.customReward) {
        return changeStep(8);
      }
    }
    if (values.importancy) return changeStep(7);
    if (values.solution) return changeStep(6);
    if (values.usPerson) return changeStep(5);
    if (values.genre && values.smoker && values.frontier) return changeStep(3);
    if (values.newOrExisting)
      return changeStep(values.newOrExisting === 'new' ? 2 : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (currentStep > 0) {
      linearScrollToBottom();
    }
    if (currentStep <= -1) {
      sendPricingForm(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    setIsError(
      JSON.stringify(errors) === JSON.stringify(initialErrors) ? false : true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    getAvailableInsuranceDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>
      <div className="container">
        {currentStep >= 0 && (
          <section className="new-or-existing">
            <h3>Wähle eine Option</h3>
            <div className="form-row">
              <input
                type="radio"
                id="new"
                name="newOrExisting"
                value="new"
                onChange={(e) => {
                  setErrors({ ...errors, currentCompany: '', currentReward: '' });
                  handleValuesChange(e)
                }}
              />
              <label className="form-radio-label" htmlFor="new">
                Ich möchte einen neuen Vorschlag bewerten
              </label>
            </div>
            <div className="form-row">
              <input
                type="radio"
                id="existing"
                name="newOrExisting"
                value="existing"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="existing">
                Ich möchte meine jetzige Dritte Säule vergleichen
              </label>
            </div>
          </section>
        )}

        {currentStep >= 1 && values.newOrExisting === 'existing' && (
          <section>
            <div className="form-row is-double">
              <div>
                <label className="form-label" htmlFor="currentCompany">
                  Bei welchem Unternehmen befindet sich deine drittes Säule?
                </label>
                <input
                  ref={el => (inputsRef.current[0] = el)}
                  type="text"
                  className="form-input"
                  id="currentCompany"
                  name="currentCompany"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.currentCompany}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <label className="form-label" htmlFor="currentReward">
                  Laufende Jahresprämie
                </label>
                <input
                  ref={el => (inputsRef.current[1] = el)}
                  type="number"
                  className="form-input"
                  id="currentReward"
                  name="currentReward"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.currentReward}</span>
              </div>
            </div>
            {currentStep === 1 && (
              <button
                type="button"
                className="btn"
                onClick={() => validateAndChangeStep(2)}
              >
                Weiter
              </button>
            )}
          </section>
        )}
        {currentStep >= 2 && (
          <section>
            <h3>Erzähl uns mehr über dich</h3>
            <p>
              Ich bin ...
              <button
                className="info-btn"
                type="button"
                onClick={() =>
                  setOpenInfoBtn(openInfoBtn === 'iam' ? '' : 'iam')
                }
              >
                {openInfoBtn === 'iam' ? <CgCloseO /> : <FiInfo />}
              </button>
              {openInfoBtn === 'iam' && (
                <span>
                  Als Nichtraucher gilt, wer in den letzten 12 Monaten vor
                  Vertragsabschluss keine Zigaretten oder höchstens zwei
                  Zigarren, Pfeifen oder sonstige Raucherwaren (ausgenommen
                  Zigaretten) pro Monat geraucht hat. Bei Änderungen der
                  Rauchgewohnheiten besteht eine Meldepflicht gemäss den
                  Allgemeinen Versicherungsbedingungen.
                  <br />
                  <br />
                  Als Raucher gilt, wer in den vergangenen 12 Monaten Zigaretten
                  oder mehr als zwei Zigarren, Pfeifen oder sonstige
                  Raucherwaren pro Monat geraucht hat. Bei Aufgabe des Rauchens
                  besteht eine Meldepflicht gemäss den Allgemeinen
                  Versicherungsbedingungen.
                  <br />
                  <br />
                  E-Zigaretten werden im Rahmen der Vertragsbestimmungen den
                  sonstigen Raucherwaren gleichgestellt.
                </span>
              )}
            </p>
            <div className="form-row is-double is-small">
              <input
                type="radio"
                id="man"
                name="genre"
                value="Männlich"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="man">
                Männlich
              </label>
              <input
                type="radio"
                id="woman"
                name="genre"
                value="Weiblich"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="woman">
                Weiblich
              </label>
            </div>
            <div className="form-row is-double is-small">
              <input
                type="radio"
                id="smoker"
                name="smoker"
                value="Ja"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="smoker">
                Raucher
              </label>
              <input
                type="radio"
                id="nonSmoker"
                name="smoker"
                value="Nein"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="nonSmoker">
                Nichtraucher
              </label>
            </div>
            <div className="form-row is-double is-small">
              <input
                type="radio"
                id="resident"
                name="frontier"
                value="CH-Ansässig"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="resident">
                CH-Ansässig
              </label>
              <input
                type="radio"
                id="frontier"
                name="frontier"
                value="Grenzgänger"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="frontier">
                Grenzgänger
              </label>
            </div>
          </section>
        )}
        {currentStep >= 3 && (
          <section>
            <div className="form-row is-double">
              <div>
                <label className="form-label" htmlFor="profession">
                  Beruf
                </label>
                <input
                  ref={el => (inputsRef.current[2] = el)}
                  type="text"
                  className="form-input"
                  id="profession"
                  name="profession"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.profession}</span>
              </div>
              <div>
                <label className="form-label" htmlFor="born">
                  Geburtsdatum
                </label>
                <input
                  ref={el => (inputsRef.current[3] = el)}
                  type="text"
                  className="form-input"
                  id="born"
                  name="born"
                  placeholder="GG.MM.AAAA"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.born}</span>
              </div>
            </div>
            <div className="form-row is-double">
              <div>
                <label className="form-label" htmlFor="nationality">
                  Nationalität
                </label>
                <input
                  ref={el => (inputsRef.current[4] = el)}
                  type="text"
                  className="form-input"
                  id="nationality"
                  name="nationality"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.nationality}</span>
              </div>
              <div>
                <label className="form-label" htmlFor="permit">
                  Aufenthaltserlaubnis
                </label>
                <select
                  className="form-select"
                  name="permit"
                  id="permit"
                  onChange={handleValuesChange}
                  value={values.permit}
                >
                  <option value="-">-</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="G">G</option>
                </select>
              </div>
            </div>
            {currentStep === 3 && (
              <button
                type="button"
                className="btn"
                onClick={() => validateAndChangeStep(4)}
              >
                Weiter
              </button>
            )}
          </section>
        )}
        {currentStep >= 4 && (
          <section>
            <p>
              Bist du eine "U.S Person"?
              <button
                className="info-btn"
                type="button"
                onClick={() =>
                  setOpenInfoBtn(openInfoBtn === 'usperson' ? '' : 'usperson')
                }
              >
                {openInfoBtn === 'usperson' ? <CgCloseO /> : <FiInfo />}
              </button>
              {openInfoBtn === 'usperson' && (
                <span>
                  Unter den Begriff fallen – unabhängig von ihrem effektiven
                  Wohnsitz – folgende Personen
                  <br />
                  <br />
                  - US-Bürger und US-Doppelbürger
                  <br />
                  - Besitzer einer Green Card
                  <br />
                  - Personen, die sich während mindestens:
                  <br />
                  - 31 Tagen im laufenden Jahr, und
                  <br />
                  - 183 Tagen im laufenden Jahr und/oder den zwei
                  vorangegangenen Jahren in den USA aufgehalten haben. Dabei
                  werden alle Tage des laufenden Jahres und 1/3 der Tage des
                  letzten Jahres vor dem laufenden Jahr und 1/6 der Tage des
                  vorletzten Jahres vor dem laufenden Jahr gezählt.
                  <br />
                  <br />
                  Wenn du eine «U.S Person» bzw. in den USA steuerpflichtig bist
                  oder wirds, bist du verpflichtet, uns dies unverzüglich zu
                  melden. Ändert sich dieser Status während der Vertragsdauer,
                  ist uns dies ebenfalls umgehend mitzuteilen.
                </span>
              )}
            </p>
            <div className="form-row is-double is-small">
              <input
                type="radio"
                id="yes-us-person"
                name="usPerson"
                value="Ja"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="yes-us-person">
                Ja
              </label>
              <input
                type="radio"
                id="no-us-person"
                name="usPerson"
                value="Nein"
                onChange={handleValuesChange}
              />
              <label className="form-radio-label" htmlFor="no-us-person">
                Nein
              </label>
            </div>
          </section>
        )}
        {currentStep >= 5 && (
          <section>
            <h3>Welche Lösung suchst du?</h3>
            <div className="form-row is-double">
              <input
                type="radio"
                id="savings"
                name="solution"
                value="Sicherheit"
                onChange={handleValuesChange}
              />
              <label
                className="form-radio-label form-radio-label-img"
                htmlFor="savings"
              >
                <img className="img" src={savings} alt="Sicherheit" />
                <span>Sicherheit</span>
                <small>
                  Ideal für diejenigen, die ihr Geld sicher aufbewahren und
                  gleichzeitig auf hohe Renditen verzichten wollen
                </small>
              </label>
              <input
                type="radio"
                id="performance"
                name="solution"
                value="Ertrag"
                onChange={handleValuesChange}
              />
              <label
                className="form-radio-label form-radio-label-img"
                htmlFor="performance"
              >
                <img className="img" src={performance} alt="Ertrag" />
                <span>Ertrag</span>
                <small>
                  Ideal für diejenigen, die auf Vermögenswachstum mit hohen
                  Renditen ausgerichtet sind
                </small>
              </label>
            </div>
          </section>
        )}
        {currentStep >= 6 && (
          <>
            <section>
              <h3>Wähl die gewünschte Leistung</h3>
              <div className="form-row has-info-btn">
                <input
                  type="checkbox"
                  id="exempt"
                  name="performance"
                  disabled
                  value="Prämienbefreiung"
                  checked={values.performance['exempt']}
                  onChange={handleValuesChange}
                />
                <label
                  className="form-radio-label has-indicator"
                  htmlFor="exempt"
                >
                  Prämienbefreiung
                  {openInfoBtn === 'exempt' && (
                    <span>
                      Im Fall einer Krankheit oder eines Unfalls zahlt die
                      Versicherung die Prämie für dich. Das gibt dir die
                      Sicherheit, das Kapital bei Fälligkeit zu erhalten, egal
                      was passiert!
                    </span>
                  )}
                </label>
                <button
                  className="info-btn"
                  type="button"
                  onClick={() =>
                    setOpenInfoBtn(openInfoBtn === 'exempt' ? '' : 'exempt')
                  }
                >
                  {openInfoBtn === 'exempt' ? <CgCloseO /> : <FiInfo />}
                </button>
              </div>
              <div className="form-row has-info-btn">
                <input
                  type="checkbox"
                  id="death"
                  name="performance"
                  value="Garantierte Todesfallleistung"
                  checked={values.performance['death']}
                  onChange={handleValuesChange}
                />
                <label
                  className="form-radio-label has-indicator"
                  htmlFor="death"
                >
                  Garantierte Todesfallleistung
                  {openInfoBtn === 'death' && (
                    <span>
                      Möchtest du ein Todesfallkapital in den Vertrag aufnehmen
                      lassen? Auf diese Weise kannst du die Sicherheit deiner
                      Familie besser schützen.
                    </span>
                  )}
                </label>
                <button
                  className="info-btn"
                  type="button"
                  onClick={() =>
                    setOpenInfoBtn(openInfoBtn === 'death' ? '' : 'death')
                  }
                >
                  {openInfoBtn === 'death' ? <CgCloseO /> : <FiInfo />}
                </button>
              </div>
              <div className="form-row has-info-btn">
                <input
                  type="checkbox"
                  id="maturity"
                  name="performance"
                  value="Garantie des angesparten Kapitals bei Fälligkeit"
                  checked={values.performance['maturity']}
                  onChange={e => {
                    if (values.solution === 'Ertrag') {
                      return toast.error(
                        'Achtung, die Kapitalgarantie ist nur in Kombination mit der Lösung "Sicherheit" möglich'
                      );
                    }
                    handleValuesChange(e);
                  }}
                />
                <label
                  className="form-radio-label has-indicator"
                  htmlFor="maturity"
                >
                  Garantie des angesparten Kapitals bei Fälligkeit
                  {openInfoBtn === 'maturity' && (
                    <span>
                      Wenn du eine konservative und sicherheitsorientierte
                      Person bist, kannst du diese Option wählen. Ein großer
                      Teil deiner Ersparnisse wird garantiert. Dadurch wird die
                      Rendite des gesparten Kapitals deutlich geringer.
                    </span>
                  )}
                </label>
                <button
                  className="info-btn"
                  type="button"
                  onClick={() =>
                    setOpenInfoBtn(openInfoBtn === 'maturity' ? '' : 'maturity')
                  }
                >
                  {openInfoBtn === 'maturity' ? <CgCloseO /> : <FiInfo />}
                </button>
              </div>
            </section>

            <section>
              <h3>Was zählt für dich am meisten?</h3>
              <div className="form-row has-info-btn">
                <input
                  type="radio"
                  id="taxes"
                  name="importancy"
                  value="Erzielung von Steuervorteilen, Einsparung von Steuern"
                  onChange={handleValuesChange}
                />
                <label
                  className="form-radio-label has-indicator"
                  htmlFor="taxes"
                >
                  Erzielung von Steuervorteilen, Einsparung von Steuern (Lösung
                  3A)
                  {openInfoBtn === 'taxes' && (
                    <span>
                      Wenn du diese Option wählst, kannst du im Durchschnitt 20%
                      der gezahlten Prämie an Steuern sparen, z.B. wenn du eine
                      Prämie von 6883 CHF pro Jahr bezahlst, sparst du 1376 CHF
                      an Steuern! Der Zugang zum Kapital ist jedoch nur aus den
                      folgenden Gründen möglich: <br />
                      <br /> - Kauf eines Eigenheims <br /> - Umzug ins Ausland{' '}
                      <br /> - Aufnahme einer selbständigen Tätigkeit <br /> -
                      Vorruhestand und Ruhestand im normalen Alter
                    </span>
                  )}
                </label>
                <button
                  className="info-btn"
                  type="button"
                  onClick={() =>
                    setOpenInfoBtn(openInfoBtn === 'taxes' ? '' : 'taxes')
                  }
                >
                  {openInfoBtn === 'taxes' ? <CgCloseO /> : <FiInfo />}
                </button>
              </div>
              <div className="form-row has-info-btn">
                <input
                  type="radio"
                  id="capital"
                  name="importancy"
                  value="Möglichkeit des Zugangs zu Kapital aus beliebigen Gründen"
                  onChange={handleValuesChange}
                />
                <label
                  className="form-radio-label has-indicator"
                  htmlFor="capital"
                >
                  Möglichkeit des Zugangs zu Kapital aus beliebigen Gründen
                  (Lösung 3B)
                  {openInfoBtn === 'capital' && (
                    <span>
                      Wenn du diese Option wählst, kannst du aus jedem
                      beliebigen Grund auf das Kapital zugreifen. Der Zugriff
                      auf das Kapital ist nach einer Laufzeit von mindestens 5
                      Jahren jederzeit möglich.
                    </span>
                  )}
                </label>
                <button
                  className="info-btn"
                  type="button"
                  onClick={() =>
                    setOpenInfoBtn(openInfoBtn === 'capital' ? '' : 'capital')
                  }
                >
                  {openInfoBtn === 'capital' ? <CgCloseO /> : <FiInfo />}
                </button>
              </div>
            </section>
          </>
        )}
        {currentStep >= 7 && (
          <section>
            <h3>Wann soll es losgehen?</h3>
            <div className="grid-2">
              <div>
                <label className="form-label" htmlFor="insuranceDate">
                  Versicherungsbeginn
                </label>
                <select
                  className="form-select"
                  name="insuranceDate"
                  id="insuranceDate"
                  onChange={handleValuesChange}
                  value={values.insuranceDate}
                >
                  {availableInsuranceDates.map((date, i) => (
                    <option key={i} value={date}>
                      {date}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="form-label" htmlFor="paymentFrequency">
                  Zahlungsintervall
                </label>
                <select
                  className="form-select"
                  name="paymentFrequency"
                  id="paymentFrequency"
                  onChange={handleValuesChange}
                  value={values.paymentFrequency}
                >
                  <option value="" disabled>
                    Wählen
                  </option>
                  <option value="Monatlich">Monatlich</option>
                  <option value="Vierteljährlich">Vierteljährlich</option>
                  <option value="Halbjährlich">Halbjährlich</option>
                  <option value="Jährlich">Jährlich</option>
                </select>
              </div>
              <div>
                <label className="form-label" htmlFor="reward">
                  Gewünschte Prämie (monatlich)
                </label>
                <select
                  className="form-select"
                  name="reward"
                  id="reward"
                  onChange={e => {
                    handleValuesChange(e);
                    clearCustomRewardError(e);
                  }}
                  value={values.reward}
                >
                  <option value="" disabled>
                    Wählen
                  </option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="250">250</option>
                  <option value="300">300</option>
                  <option value="350">350</option>
                  <option value="400">400</option>
                  <option value="450">450</option>
                  <option value="500">500</option>
                  <option value="588">588</option>
                  <option value="custom">Anpassen...</option>
                </select>
              </div>
              {values.reward === 'custom' && (
                <div>
                  <label className="form-label" htmlFor="customReward">
                    Anpassen Gewünschte Prämie
                  </label>
                  <input
                    ref={el => (inputsRef.current[5] = el)}
                    type="number"
                    className="form-input"
                    id="customReward"
                    name="customReward"
                    onChange={handleValuesChange}
                    onBlur={validateInput}
                  />
                  <span className="form-error">{errors.customReward}</span>
                </div>
              )}
            </div>
          </section>
        )}
        {currentStep >= 8 && (
          <section>
            <h3>Perfekt! Noch ein paar Angaben</h3>
            <div className="form-row is-double">
              <div>
                <label className="form-label" htmlFor="fullName">
                  Name und Nachname
                </label>
                <input
                  ref={el => (inputsRef.current[6] = el)}
                  type="text"
                  className="form-input"
                  id="fullName"
                  name="fullName"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.fullName}</span>
              </div>
              <div>
                <label className="form-label" htmlFor="address">
                  Vollständige Adresse (Straße, Ort)
                </label>
                <input
                  type="text"
                  className="form-input"
                  id="address"
                  name="address"
                  onChange={handleValuesChange}
                />
              </div>
            </div>
            <div className="form-row is-double">
              <div>
                <label className="form-label" htmlFor="email">
                  E-Mail
                </label>
                <input
                  ref={el => (inputsRef.current[7] = el)}
                  type="email"
                  className="form-input"
                  id="email"
                  name="email"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.email}</span>
              </div>
              <div>
                <label className="form-label" htmlFor="phone">
                  Telefon
                </label>
                <input
                  ref={el => (inputsRef.current[8] = el)}
                  type="tel"
                  className="form-input"
                  id="phone"
                  name="phone"
                  onChange={handleValuesChange}
                  onBlur={validateInput}
                />
                <span className="form-error">{errors.phone}</span>
              </div>
            </div>
            <div className="form-row">
              <label className="default-checkbox" htmlFor="contact-me">
                <input
                  type="radio"
                  id="contact-me"
                  name="contact"
                  value="Ja"
                  checked={values.contact === 'Ja'}
                  onChange={handleValuesChange}
                />
                Ich möchte die Vorschläge mit einem Experten bewerten. Kontaktieren Sie mich telefonisch.
              </label>
            </div>
            <div className="form-row">
              <label className="default-checkbox" htmlFor="dont-contact-me">
                <input
                  type="radio"
                  id="dont-contact-me"
                  name="contact"
                  value="Nein"
                  checked={values.contact === 'Nein'}
                  onChange={handleValuesChange}
                />
                Ich kann die Vorschläge individuell bewerten. Ich möchte nicht kontaktiert werden.
              </label>
            </div>
            <div className="form-row">
              <label className="form-label" htmlFor="remarks">
                Bemerkungen
              </label>
              <textarea
                className="form-textarea"
                name="remarks"
                id="remarks"
                rows="5"
                onChange={handleValuesChange}
              ></textarea>
            </div>
            {currentStep === 8 && (
              <button
                type="button"
                className="btn"
                onClick={() => validateAndChangeStep(-1)}
                style={{
                  textTransform: 'none',
                  background: '#1CC691',
                  borderColor: '#1CC691',
                }}
              >
                Entdecken Sie die beste Lösung
              </button>
            )}
          </section>
        )}

        {currentStep === -1 && (
          <section className="full-width-section">
            <div className="loading loading-center"></div>
          </section>
        )}
      </div>
    </Wrapper>
  );
};

export default Pricing;
