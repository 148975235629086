export const getFirstOfMonthDateString = (year, month) => {
  return new Intl.DateTimeFormat('it-IT')
    .format(new Date(year, month, 1))
    .replaceAll('/', '.');
};

export const validateUserInput = (name, value) => {
  let error = '';

  if (name === 'born') {
    if (!/^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
      error = 'Überprüfen Sie das Format';
    } else {
      const [day, month, year] = value.split('.');
      const date = new Date(`${year}/${month}/${day}`);

      if (
        !(date instanceof Date) ||
        isNaN(date) ||
        date.getDate() !== Number(day) ||
        date.getMonth() + 1 !== Number(month) ||
        date.getFullYear() !== Number(year) ||
        Number(year) < 1902
      ) {
        error = 'Ungültiges Datum';
      }

      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

      if (date > eighteenYearsAgo) {
        error = 'Sie müssen mindestens 18 Jahre alt sein';
      }
    }
  }
  if (name === 'height') {
    if (!/^\d+$/.test(value)) {
      error = 'Bitte gebe eine Nummer ein';
    } else {
      if (Number(value) < 80) {
        error = 'Der Wert muss größer oder gleich 80 sein';
      }
      if (Number(value) > 240) {
        error = 'Der Wert muss kleiner oder gleich 240 sein';
      }
    }
  }
  if (name === 'weight') {
    if (!/^\d+$/.test(value)) {
      error = 'Bitte gebe eine Nummer ein';
    } else {
      if (Number(value) < 30) {
        error = 'Der Wert muss größer oder gleich 30 sein';
      }
      if (Number(value) > 300) {
        error = 'Der Wert muss kleiner oder gleich 300 sein';
      }
    }
  }
  if (name === 'email') {
    if (!/\b[\w.-]+@[\w.-]+\.\w{2,4}\b/i.test(value)) {
      error = 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
    }
  }
  if (name === 'phone') {
    const tempValue = value.replace(/[()-\s.]/g, '');
    if (
      !/^\+([0-9]{1,3})([0-9]{3})([0-9]{3})([0-9]{3,4})$/.test(tempValue) &&
      !/^([0-9]{3})([0-9]{3})([0-9]{3,4})$/.test(tempValue) &&
      !/^0{2}([0-9]{1,3})([0-9]{3})([0-9]{3})([0-9]{3,4})$/.test(tempValue)
    ) {
      error = 'Ungültiges Telefon';
    }
  }
  if (name === 'fullName') {
    if (value.replaceAll(' ', '').length < 4) {
      error = 'Geben Sie mindestens 4 Buchstaben ein';
    }
  }
  if (
    name === 'profession' ||
    name === 'nationality' ||
    name === 'postalCode'
  ) {
    if (value.trim().length < 4) {
      error = 'Geben Sie mindestens 4 Buchstaben ein';
    }
  }
  if (
    name === 'customReward' ||
    name === 'customReward2' ||
    name === 'currentReward'
  ) {
    if (!/^\d+$/.test(value)) {
      error = 'Bitte gebe eine Nummer ein';
    }
  }
  if (name === 'via' || name === 'currentCompany') {
    if (!value) {
      error = 'Geben Sie einen Wert ein';
    }
  }

  return error;
};

export const linearScrollToBottom = () => {
  const topScroll = document.documentElement.scrollTop;
  const bottomScroll = Math.ceil(topScroll + window.innerHeight);
  const marginOfError = 2;

  if (bottomScroll + marginOfError >= document.documentElement.offsetHeight)
    return;

  let scrollY = topScroll;
  let oldTimestamp = null;

  function step(newTimestamp) {
    if (oldTimestamp !== null) {
      scrollY += 5;
      if (scrollY >= document.documentElement.offsetHeight - window.innerHeight)
        return window.scrollTo({
          top: document.documentElement.offsetHeight,
        });
      window.scrollTo({ top: scrollY });
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }

  window.requestAnimationFrame(step);
};

export const formatTimestampToFullDate = timestamp => {
  return new Intl.DateTimeFormat('de-DE', {
    dateStyle: 'full',
  }).format(new Date(timestamp));
};

export const decodeHtml = html => {
  if (!html) return null;

  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};
