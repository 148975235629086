import {
  BEGIN_REQUEST,
  FINISH_REQUEST,
  REQUEST_ERROR,
  DISPLAY_ALERT,
  CLEAR_ALERT,
  GET_PUBLIC_POSTS_SUCCESS,
  GET_SINGLE_PUBLIC_POST_SUCCESS,
} from './actions';

const reducer = (state, action) => {
  if (action.type === BEGIN_REQUEST) {
    return { ...state, appLoading: true };
  }
  if (action.type === FINISH_REQUEST) {
    return {
      ...state,
      appLoading: false,
      showAlert: action.payload.showAlert || state.showAlert,
      alertType: action.payload.alertType || state.alertType,
      alertText: action.payload.alertText || state.alertText,
    };
  }
  if (action.type === REQUEST_ERROR) {
    return {
      ...state,
      appLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.message,
    };
  }
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: action.payload.alertType,
      alertText: action.payload.alertText,
    };
  }
  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: '',
      alertText: '',
    };
  }
  if (action.type === GET_PUBLIC_POSTS_SUCCESS) {
    return {
      ...state,
      appLoading: false,
      posts: {
        ...state.posts,
        categories: action.payload.categories,
        featuredPost: action.payload.featuredPost,
        data: action.payload.posts,
        pages: action.payload.numOfPages,
      },
    };
  }
  if (action.type === GET_SINGLE_PUBLIC_POST_SUCCESS) {
    return {
      ...state,
      appLoading: false,
      singlePost: action.payload.post,
    };
  }
  throw new Error(`No such action: ${action.type}`);
};

export default reducer;
