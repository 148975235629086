import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaWhatsapp } from 'react-icons/fa';
import { HiOutlineMail, HiOutlineCalendar } from 'react-icons/hi';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import contact from '../../assets/images/contact-us.svg';
import Wrapper from './Contact_styles';

const metaTitle =
  'Dritte Säule - Kontaktieren Sie uns jetzt für ein neues Angebot';
const metaDescription =
  'Kontaktieren Sie uns jetzt für weitere Informationen und ein schnelles Angebot für eine neue Lösung. Sie wählen die Kontaktmethode: per Whatsapp oder E-Mail, wir antworten Ihnen so schnell wie möglich.';

const Contact = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  return (
    <Wrapper>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>
      <div className="container">
        <section className="hero-section">
          <h1>Kontakt</h1>
          <p className="hero-text">
            Wir sind für dich da, wenn du uns brauchst. Auf dem Kanal, der für
            dich am besten geeignet ist.
          </p>
          <img className="img" src={contact} alt="Contact Us" />
        </section>

        <section className="contact">
          <div className="item phone-us">
            <button
              type="button"
              onClick={() =>
                setOpenAccordion(openAccordion === 'phone' ? null : 'phone')
              }
            >
              <h5>
                Chat mit uns über WhatsApp{' '}
                {openAccordion === 'phone' ? (
                  <BiMinusCircle />
                ) : (
                  <BiPlusCircle />
                )}
              </h5>
            </button>
            <div
              className={
                openAccordion === 'phone' ? 'accordion open' : 'accordion'
              }
            >
              <div>
                <h3>Können wir dir helfen?</h3>
                <p>Chat mit uns über WhatsApp</p>
                <a
                  href="https://wa.me/+41918808787"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-outline btn-hero"
                >
                  <FaWhatsapp />
                </a>
              </div>
            </div>
          </div>

          <div className="item mail-us">
            <button
              type="button"
              onClick={() =>
                setOpenAccordion(openAccordion === 'mail' ? null : 'mail')
              }
            >
              <h5>
                Schreib uns eine E-Mail{' '}
                {openAccordion === 'mail' ? (
                  <BiMinusCircle />
                ) : (
                  <BiPlusCircle />
                )}
              </h5>
            </button>
            <div
              className={
                openAccordion === 'mail' ? 'accordion open' : 'accordion'
              }
            >
              <div>
                <h3>Können wir dir helfen?</h3>
                <p>Schreib uns eine E-Mail</p>
                <a
                  href="mailto:info@elefin.ch"
                  rel="noopener noreferrer"
                  className="btn btn-outline btn-hero"
                >
                  <HiOutlineMail />
                </a>
              </div>
            </div>
          </div>

          <div className="item schedule">
            <button
              type="button"
              onClick={() =>
                setOpenAccordion(openAccordion === 'schedule' ? null : 'schedule')
              }
            >
              <h5>
              Buche einen Termin
                {openAccordion === 'schedule' ? (
                  <BiMinusCircle />
                ) : (
                  <BiPlusCircle />
                )}
              </h5>
            </button>
            <div
              className={
                openAccordion === 'schedule' ? 'accordion open' : 'accordion'
              }
            >
              <div>
                <h3>Können wir dir helfen?</h3>
                <p>Buche einen Termin</p>
                <a
                  href="https://calendly.com/terzopilastro"
                  target='_blank'
                  rel="noopener noreferrer"
                  className="btn btn-outline btn-hero"
                >
                  <HiOutlineCalendar />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Contact;
