import { Route, Routes, Outlet, useLocation, Navigate } from 'react-router-dom';
import {
    Home,
    Pricing,
    ThankYou,
    Contact,
    Faq,
    BlogPosts,
    SinglePost,
    FinalForm,
    FinalFormThankYou,
} from './pages';
import { NavBar, CookiesWarning, Footer } from './components';

const LandingPageLayout = () => {
    const { pathname } = useLocation();

    return (
        <>
            <CookiesWarning />
            <NavBar />
            <Outlet />
            {pathname !== '/pricing' && <Footer />}
        </>
    );
};

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<LandingPageLayout />}>
                <Route index element={<Home />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="thank-you" element={<ThankYou />} />
                <Route path="contact" element={<Contact />} />
                <Route path="faq" element={<Faq />} />
                <Route path="info">
                    <Route index element={<BlogPosts />} />
                    <Route path=":slug" element={<SinglePost />} />
                </Route>
                <Route path="final-form">
                    <Route index element={<FinalForm />} />
                    <Route path="thank-you" element={<FinalFormThankYou />} />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    );
};

export default App;
