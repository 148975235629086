import React, { useReducer, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { PUBLIC_POSTS_PER_PAGE } from '../utils/constants';
import reducer from './appReducer';
import {
    BEGIN_REQUEST,
    FINISH_REQUEST,
    REQUEST_ERROR,
    DISPLAY_ALERT,
    CLEAR_ALERT,
    GET_PUBLIC_POSTS_SUCCESS,
    GET_SINGLE_PUBLIC_POST_SUCCESS,
} from './actions';

const initialState = {
    appLoading: false,
    showAlert: false,
    alertType: '',
    alertText: '',
    posts: {
        categories: [],
        featuredPost: null,
        data: [],
        pages: 1,
    },
    singlePost: null,
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const navigate = useNavigate();

    const [state, dispatch] = useReducer(reducer, initialState);

    const sendPricingForm = async values => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            await axios.post(`/api/v1/contact/pricing`, values);
            dispatch({
                type: FINISH_REQUEST,
                payload: {
                    showAlert: true,
                    alertText: 'Ihre Anfrage wurde verschickt',
                    alertType: 'success',
                },
            });
            navigate('/thank-you');
        } catch (error) {
            console.log(error);
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }
    };

    const sendFinalForm = async values => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            await axios.post(`/api/v1/contact/final-form`, values);
            dispatch({
                type: FINISH_REQUEST,
                payload: {
                    showAlert: true,
                    alertText: 'Ihre Anfrage wurde verschickt',
                    alertType: 'success',
                },
            });
            const token = crypto.randomUUID();
            localStorage.setItem('token', token.toString());
            localStorage.setItem(
                'userData',
                JSON.stringify({ fullName: values.fullName, avs: values.avs })
            );
            navigate(`/final-form/thank-you?token=${token}`);
        } catch (error) {
            console.log(error);
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }
    };

    const sendFinalFormFiles = async values => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            await axios.post(`/api/v1/contact/final-form-files`, values);
            dispatch({
                type: FINISH_REQUEST,
                payload: {
                    showAlert: true,
                    alertText: 'Ihre Anfrage wurde verschickt',
                    alertType: 'success',
                },
            });
            localStorage.removeItem('token');
            localStorage.removeItem('userData');
            navigate('/final-form/thank-you');
        } catch (error) {
            console.log(error);
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }
    };

    const getPosts = async query => {
        const { search, category } = query;

        let url = `/api/v1/posts/public/DE?category=${category}&search=${search}`;

        dispatch({ type: BEGIN_REQUEST });
        try {
            const { data } = await axios.get(url);
            const { featuredPost, posts, availableCategories } = data;
            const numOfPages = Math.ceil(posts.length / PUBLIC_POSTS_PER_PAGE);
            dispatch({
                type: GET_PUBLIC_POSTS_SUCCESS,
                payload: {
                    featuredPost,
                    posts,
                    numOfPages,
                    categories: availableCategories,
                },
            });
        } catch (error) {
            console.log(error.response);
            dispatch({
                type: REQUEST_ERROR,
                payload: { message: error.response.data.message },
            });
        }
    };

    const getSinglePost = async slug => {
        dispatch({ type: BEGIN_REQUEST });
        try {
            const { data } = await axios.get(`/api/v1/posts/public/DE/${slug}`);
            const { post } = data;
            dispatch({
                type: GET_SINGLE_PUBLIC_POST_SUCCESS,
                payload: { post },
            });
        } catch (error) {
            navigate('/info');
        }
    };

    const showAlert = ({ alertType, alertText }) => {
        dispatch({ type: DISPLAY_ALERT, payload: { alertType, alertText } });
    };

    useEffect(() => {
        const { showAlert, alertType, alertText } = state;

        if (showAlert) {
            if (alertType === 'success') {
                toast.success(alertText);
            }
            if (alertType === 'danger') {
                toast.error(alertText);
            }
            if (alertType === 'info') {
                toast.info(alertText);
            }

            setTimeout(() => {
                dispatch({ type: CLEAR_ALERT });
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.showAlert]);

    return (
        <AppContext.Provider
            value={{
                ...state,
                sendPricingForm,
                sendFinalForm,
                sendFinalFormFiles,
                getPosts,
                getSinglePost,
                showAlert,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

const useAppContext = () => {
    return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
