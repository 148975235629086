import { useState } from 'react';
import { Link } from 'react-router-dom';
import Wrapper from './CookiesWarning_styles';

const CookiesWarning = () => {
  const [showWarning, setShowWarning] = useState(
    localStorage.getItem('cookiesConsent') === 'true' ? false : true
  );

  const acceptCookies = () => {
    localStorage.setItem('cookiesConsent', 'true');
    setShowWarning(false);
  };

  return (
    <Wrapper style={{ display: showWarning ? 'block' : 'none' }}>
      <div className="container">
        <p>
          Wir verwenden Cookies 🍪, um Ihnen ein hervorragendes Benutzererlebnis
          zu bieten. Indem Sie weiter auf unserer Website surfen, stimmen Sie
          der Verarbeitung zu Daten. Weitere Informationen finden Sie in den
          Bestimmungen zu Datenschutz.
        </p>
        <div className="action-container">
          <Link to="#">Mehr Informationen</Link>
          <button
            type="button"
            className="btn btn-outline"
            onClick={acceptCookies}
          >
            OK
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default CookiesWarning;
