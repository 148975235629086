import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useAppContext } from '../../context/appContext';
import { DropzoneUploader, Camera, Loading } from '../../components';
import finishLine from '../../assets/images/finish-line.svg';
import Wrapper from './ThankYou_styles';

const metaTitle = 'Dritte Säule - Thank You';

const ThankYou = () => {
    const { sendFinalFormFiles, appLoading } = useAppContext();
    const [searchParams] = useSearchParams();

    const storedToken = localStorage.getItem('token');
    const token = searchParams.get('token');
    const userData = JSON.parse(localStorage.getItem('userData') || 'null');

    const isValidToken = !!storedToken && !!userData && token === storedToken;

    const [isTakingPicture, setIsTakingPicture] = useState(false);

    const [cameraFiles, setCameraFiles] = useState([]);
    const [uploads, setUploads] = useState([]);
    const [isUploadInProgress, setIsUploadInProgress] = useState(false);
    const [isUploadError, setIsUploadError] = useState(false);

    const handleSubmit = () => {
        if (uploads.length < 1) {
            toast.error('Bitte laden Sie mindestens eine Datei hoch');
            return;
        }
        if (isUploadInProgress) {
            toast.error('Bitte warten Sie, bis alle Dateien hochgeladen sind');
            return;
        }
        if (isUploadError) {
            toast.error(
                'Bei einigen Ihrer hochgeladenen Dateien scheint ein Fehler aufgetreten zu sein'
            );
            return;
        }
        setCameraFiles([]);
        sendFinalFormFiles({
            images: uploads.map(upload => upload.url),
            userData,
        });
    };

    return (
        <Wrapper className="container">
            <Helmet>
                <title>{metaTitle}</title>
                <meta property="og:title" content={metaTitle} />
                <meta name="robots" content="noindex" />
            </Helmet>
            <section>
                {appLoading ? (
                    <Loading center />
                ) : (
                    <>
                        <h3>Vielen Dank für das Ausfüllen des Formulars</h3>
                        <p>Ihre Anfrage wird schnellstmöglich bearbeitet</p>
                        {isValidToken ? (
                            <>
                                <p>
                                    <b>
                                        Bitte laden Sie jetzt folgende Dokumente
                                        hoch:
                                    </b>
                                </p>
                                <div className="list-container">
                                    <ul>
                                        <li>
                                            Vorder-/Rückseitenkopie des
                                            Personalausweises oder Reisepasses
                                        </li>
                                        <li>
                                            Kopie der Vorder-/Rückseite des
                                            Aufenthaltstitels{' '}
                                            <span className="colored">
                                                (nicht benötigt)
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <DropzoneUploader
                                    initialFiles={cameraFiles}
                                    setUploads={setUploads}
                                    setIsUploadInProgress={
                                        setIsUploadInProgress
                                    }
                                    setIsUploadError={setIsUploadError}
                                />
                                <button
                                    type="button"
                                    className="btn center-btn"
                                    onClick={() => setIsTakingPicture(true)}
                                >
                                    Machen Sie ein Foto
                                </button>
                                <button
                                    type="button"
                                    className="btn center-btn"
                                    onClick={handleSubmit}
                                    style={{
                                        background: '#1CC691',
                                        borderColor: '#1CC691',
                                    }}
                                >
                                    Entdecken Sie die beste Lösung
                                </button>
                                {isTakingPicture && (
                                    <Camera
                                        onClose={() =>
                                            setIsTakingPicture(false)
                                        }
                                        onCameraError={error => {
                                            setIsTakingPicture(false);
                                            const errorMessage =
                                                'Beim Initialisieren der Kamera ist ein Fehler aufgetreten. Stellen Sie sicher, dass Sie den Kamerazugriff in Ihren Browsereinstellungen zulassen';
                                            toast.error(errorMessage);
                                        }}
                                        onTakePhotoAnimationDone={dataUri => {
                                            fetch(dataUri).then(res => {
                                                res.arrayBuffer().then(buf => {
                                                    const file = new File(
                                                        [buf],
                                                        'image_data_url.jpg',
                                                        { type: 'image/jpeg' }
                                                    );
                                                    setCameraFiles([file]);
                                                });
                                            });
                                            setIsTakingPicture(false);
                                        }}
                                        idealResolution={{
                                            width: 1080,
                                            height: 1080,
                                        }}
                                        isSilentMode
                                        isDisplayStartCameraError={false}
                                    />
                                )}
                            </>
                        ) : (
                            <img
                                className="img"
                                src={finishLine}
                                alt="Finish Line"
                            />
                        )}
                    </>
                )}
            </section>
        </Wrapper>
    );
};
export default ThankYou;
