import { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useAppContext } from '../../context/appContext';
import { Loading } from '../../components';
import { CgCloseO } from 'react-icons/cg';
import Wrapper from './FinalForm_styles';

const metaTitle = 'Dritte Säule - Finale Form';

const emptyHealthProblem = { illness: '', date: '' };
const emptySurgeryContent = {
    illness: '',
    date: '',
    doctorNameAndAddress: '',
    isConcluded: null,
};
const emptyMedicationContent = {
    name: '',
    date: '',
    doctorNameAndAddress: '',
};

const initialForm = {
    fullName: '',
    avs: '',
    taxCode: '',
    civilStatus: '',
    address: '',
    height: '',
    weight: '',
    declined: {
        value: null,
        content: '',
    },
    smoker: {
        value: null,
        content: '',
    },
    atRisk: {
        value: null,
        content: '',
    },
    abroad: {
        value: null,
        content: '',
    },
    drugs: {
        value: null,
        content: '',
    },
    hiv: {
        value: null,
        content: '',
    },
    healthProblem: {
        value: null,
        content: [emptyHealthProblem],
    },
    surgery: {
        value: null,
        content: [emptySurgeryContent],
    },
    noWork: {
        value: null,
        content: [emptySurgeryContent],
    },
    medications: {
        value: null,
        content: [emptyMedicationContent],
    },
    doctorNameAndAddress: '',
    remarks: '',
};

const notRequiredFields = ['taxCode', 'doctorNameAndAddress', 'remarks'];

const FinalForm = () => {
    const { sendFinalForm, appLoading } = useAppContext();

    const [form, setForm] = useState(initialForm);

    const handleFormChange = (name, value, objKey = null) =>
        setForm(prev => {
            if (objKey) {
                return {
                    ...prev,
                    [objKey]: {
                        ...prev[objKey],
                        [name]: value,
                        ...(name === 'value' && value === false
                            ? {
                                  content: Array.isArray(
                                      initialForm[objKey].content
                                  )
                                      ? [...initialForm[objKey].content]
                                      : '',
                              }
                            : {}),
                    },
                };
            }
            return { ...prev, [name]: value };
        });

    const handleToggleNestedContent = (name, indexToRemove = null) =>
        setForm(prev => ({
            ...prev,
            [name]: {
                ...prev[name],
                content:
                    indexToRemove === null
                        ? [
                              ...prev[name].content,
                              {
                                  id: crypto.randomUUID(),
                                  ...initialForm[name].content[0],
                              },
                          ]
                        : prev[name].content.filter(
                              (_, i) => i !== indexToRemove
                          ),
            },
        }));

    const handleNestedContentChange = (name, value, objKey, index) =>
        setForm(prev => ({
            ...prev,
            [objKey]: {
                ...prev[objKey],
                content: prev[objKey].content.map((content, i) => {
                    if (i === index) {
                        return {
                            ...content,
                            [name]: value,
                        };
                    }
                    return content;
                }),
            },
        }));

    const handleSubmit = () => {
        const hasMissingFields = Object.entries(form).some(([key, value]) => {
            if (notRequiredFields.includes(key)) return false;
            if (typeof value === 'string') {
                return value === '';
            }
            if (value.value === true) {
                if (typeof value.content === 'string') {
                    return value.content === '';
                }
                return value.content.some(
                    content =>
                        Object.values(content).includes('') ||
                        Object.values(content).includes(null)
                );
            }
            return value.value === null;
        });

        if (hasMissingFields) {
            toast.error('Bitte füllen Sie alle Felder aus');
            return;
        }

        sendFinalForm(form);
    };

    const formContent = (
        <>
            <section className="full-width-section">
                <h1>Endgültiges Angebotsformular</h1>
                <div style={{ maxWidth: 770, margin: '0 auto' }}>
                    <p className="hero-text">
                        Sehr geehrter Kunde, dieses Formular ist der letzte
                        Schritt vor der elektronischen Unterschrift und wird
                        benötigt, um alle für den Vertragsschluss nützlichen
                        Informationen zu sammeln.
                    </p>
                    <p className="hero-text">
                        Die in diesem Formular erhobenen Daten sind vertraulich
                        und werden nicht an Dritte weitergegeben (außer dem
                        Partner, mit dem Sie sich für einen Vertragsabschluss
                        entschieden haben)
                    </p>
                </div>
            </section>
            <section>
                <div className="form-row is-double">
                    <InputRow
                        label="Name und Nachname"
                        name="fullName"
                        onChange={e =>
                            handleFormChange('fullName', e.target.value)
                        }
                    />
                    <InputRow
                        label="AHV-Nummer"
                        name="avs"
                        onChange={e => handleFormChange('avs', e.target.value)}
                    />
                </div>
                <div className="form-row is-double">
                    <InputRow
                        label="Steuernummer (bei Wohnsitz im Ausland)"
                        name="taxCode"
                        onChange={e =>
                            handleFormChange('taxCode', e.target.value)
                        }
                    />
                    <div>
                        <label className="form-label" htmlFor="civilStatus">
                            Familienstand
                        </label>
                        <select
                            className="form-select"
                            id="civilStatus"
                            onChange={e =>
                                handleFormChange('civilStatus', e.target.value)
                            }
                            value={form.civilStatus}
                        >
                            <option value="" disabled>
                                Wählen
                            </option>
                            <option value="Zölibatär / Mädchen">
                                Zölibatär / Mädchen
                            </option>
                            <option value="Verheiratet">Verheiratet</option>
                            <option value="Geschieden">Geschieden</option>
                            <option value="Witwer / Witwe">
                                Witwer / Witwe
                            </option>
                        </select>
                    </div>
                </div>
                <InputRow
                    className="form-row"
                    label="Vollständige Wohnadresse"
                    name="address"
                    placeholder="Straße, Hausnummer und Wohngemeinde"
                    onChange={e => handleFormChange('address', e.target.value)}
                />
            </section>
            <section>
                <div className="form-row is-double">
                    <InputRow
                        label="Höhe"
                        name="height"
                        placeholder="175 cm"
                        maxLength={3}
                        value={form.height}
                        onChange={e => {
                            if (/\D/g.test(e.target.value)) return;
                            handleFormChange('height', e.target.value);
                        }}
                    />
                    <InputRow
                        label="Gewicht"
                        name="weight"
                        placeholder="75 kg"
                        maxLength={3}
                        value={form.weight}
                        onChange={e => {
                            if (/\D/g.test(e.target.value)) return;
                            handleFormChange('weight', e.target.value);
                        }}
                    />
                </div>
            </section>
            <section>
                <BooleanRadioOptions
                    title="Wurden bei Ihnen Lebensversicherungsanträge anderer Unternehmen abgelehnt oder teilweise angenommen?"
                    option="declined"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'declined')
                    }
                />
                {form.declined.value && (
                    <InputRow
                        className="form-row"
                        label="Versicherungsgesellschaft und Grund"
                        name="declinedContent"
                        placeholder="Name der Versicherungsgesellschaft und Grund der Ablehnung / Teilannahme"
                        onChange={e =>
                            handleFormChange(
                                'content',
                                e.target.value,
                                'declined'
                            )
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Haben Sie in den letzten 12 Monaten Zigaretten oder Tabakwaren geraucht?"
                    option="smoker"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'smoker')
                    }
                />
                {form.smoker.value && (
                    <InputRow
                        className="form-row"
                        label="Produkt und Menge"
                        name="smokerContent"
                        placeholder="Welches Produkt? Tägliche Menge?"
                        onChange={e =>
                            handleFormChange(
                                'content',
                                e.target.value,
                                'smoker'
                            )
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Sind Sie bei der Arbeit oder in Ihrer Freizeit besonderen Risiken ausgesetzt?"
                    option="atRisk"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'atRisk')
                    }
                />
                {form.atRisk.value && (
                    <InputRow
                        className="form-row"
                        label="Risiken, denen es ausgesetzt ist"
                        name="atRiskContent"
                        placeholder="Welchen besonderen Risiken ist sie ausgesetzt?"
                        onChange={e =>
                            handleFormChange(
                                'content',
                                e.target.value,
                                'atRisk'
                            )
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Ist in den nächsten 24 Monaten ein Auslandsaufenthalt von mehr als 6 Monaten geplant?"
                    option="abroad"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'abroad')
                    }
                />
                {form.abroad.value && (
                    <InputRow
                        className="form-row"
                        label="Wo, Grund und Dauer"
                        name="abroadContent"
                        placeholder="Wo, aus welchem Grund und Dauer des Aufenthalts"
                        onChange={e =>
                            handleFormChange(
                                'content',
                                e.target.value,
                                'abroad'
                            )
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Haben Sie in den letzten 5 Jahren Betäubungsmittel oder Drogen jeglicher Art konsumiert?"
                    option="drugs"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'drugs')
                    }
                />
                {form.drugs.value && (
                    <InputRow
                        className="form-row"
                        label="Stoffe und Mengen"
                        name="drugsContent"
                        placeholder="Welche, wie lange und wöchentliche Menge"
                        onChange={e =>
                            handleFormChange('content', e.target.value, 'drugs')
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Wurde bei Ihnen eine HIV-Infektion diagnostiziert?"
                    option="hiv"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'hiv')
                    }
                />
                {form.hiv.value && (
                    <InputRow
                        className="form-row"
                        label="Seit wann"
                        name="hivContent"
                        placeholder="Ab welchem Datum?"
                        onChange={e =>
                            handleFormChange('content', e.target.value, 'hiv')
                        }
                    />
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Leiden Sie von Geburt an unter gesundheitlichen Problemen oder angeborenen Gebrechen?"
                    option="healthProblem"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'healthProblem')
                    }
                />
                {form.healthProblem.value && (
                    <>
                        {form.healthProblem.content.map(({ id }, i) => (
                            <Fragment key={id || i}>
                                {i !== 0 && (
                                    <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                            handleToggleNestedContent(
                                                'healthProblem',
                                                i
                                            )
                                        }
                                    >
                                        <CgCloseO />
                                    </button>
                                )}
                                <div className="form-row is-double">
                                    <InputRow
                                        label="Kerl"
                                        name={`healthProblemIllness${i}`}
                                        placeholder="Was für eine Störung?"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'illness',
                                                e.target.value,
                                                'healthProblem',
                                                i
                                            )
                                        }
                                    />
                                    <InputRow
                                        label="Seit wann"
                                        name={`healthProblemDate${i}`}
                                        placeholder="Ab wann?"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'date',
                                                e.target.value,
                                                'healthProblem',
                                                i
                                            )
                                        }
                                    />
                                </div>
                            </Fragment>
                        ))}
                        {form.healthProblem.content.length < 3 && (
                            <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                    handleToggleNestedContent('healthProblem')
                                }
                            >
                                Fügen Sie weitere angeborene Leiden/Gebrechen
                                hinzu
                            </button>
                        )}
                    </>
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Hatten Sie in den letzten 10 Jahren Krankheiten, Verletzungen oder Operationen? Ist in Zukunft eine Vorsorgeuntersuchung wegen eines gesundheitlichen Problems geplant?"
                    option="surgery"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'surgery')
                    }
                />
                {form.surgery.value && (
                    <>
                        {form.surgery.content.map(({ id }, i) => (
                            <Fragment key={id || i}>
                                {i !== 0 && (
                                    <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                            handleToggleNestedContent(
                                                'surgery',
                                                i
                                            )
                                        }
                                    >
                                        <CgCloseO />
                                    </button>
                                )}
                                <div className="form-row is-double">
                                    <InputRow
                                        label="Kerl"
                                        name={`surgeryIllness${i}`}
                                        placeholder="Geben Sie die Art der Krankheit oder Verletzung an"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'illness',
                                                e.target.value,
                                                'surgery',
                                                i
                                            )
                                        }
                                    />
                                    <InputRow
                                        label="Datum"
                                        name={`surgeryDate${i}`}
                                        placeholder="Von wann bis zu welchem ​​Datum?"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'date',
                                                e.target.value,
                                                'surgery',
                                                i
                                            )
                                        }
                                    />
                                </div>
                                <InputRow
                                    className="form-row"
                                    label="Name und Adresse des Arztes"
                                    name={`surgeryDoctorAndAddress${i}`}
                                    placeholder="Name und Anschrift des behandelnden Arztes"
                                    onChange={e =>
                                        handleNestedContentChange(
                                            'doctorNameAndAddress',
                                            e.target.value,
                                            'surgery',
                                            i
                                        )
                                    }
                                />
                                <div className="form-row">
                                    <span className="block-center">
                                        Ist die Behandlung ohne Folgen
                                        abgeschlossen und geheilt?
                                    </span>
                                    <div className="default-radio-container">
                                        <label>
                                            <input
                                                type="radio"
                                                name={`surgeryConcluded${i}`}
                                                onChange={e =>
                                                    handleNestedContentChange(
                                                        'isConcluded',
                                                        e.target.checked,
                                                        'surgery',
                                                        i
                                                    )
                                                }
                                            />
                                            Ja
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name={`surgeryConcluded${i}`}
                                                onChange={e =>
                                                    handleNestedContentChange(
                                                        'isConcluded',
                                                        !e.target.checked,
                                                        'surgery',
                                                        i
                                                    )
                                                }
                                            />
                                            Nein
                                        </label>
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                        {form.surgery.content.length < 3 && (
                            <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                    handleToggleNestedContent('surgery')
                                }
                            >
                                Zusätzliche Krankheit/Verletzung/Operation
                                hinzufügen
                            </button>
                        )}
                    </>
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Waren Sie in den letzten 5 Jahren an mehr als 14 aufeinanderfolgenden Tagen arbeitsunfähig?"
                    option="noWork"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'noWork')
                    }
                />
                {form.noWork.value && (
                    <>
                        {form.noWork.content.map(({ id }, i) => (
                            <Fragment key={id || i}>
                                {i !== 0 && (
                                    <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                            handleToggleNestedContent(
                                                'noWork',
                                                i
                                            )
                                        }
                                    >
                                        <CgCloseO />
                                    </button>
                                )}
                                <div className="form-row is-double">
                                    <InputRow
                                        label="Kerl"
                                        name={`noWorkIllness${i}`}
                                        placeholder="Geben Sie die Art der Krankheit oder Verletzung an"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'illness',
                                                e.target.value,
                                                'noWork',
                                                i
                                            )
                                        }
                                    />
                                    <InputRow
                                        label="Datum"
                                        name={`noWorkDate${i}`}
                                        placeholder="Von wann bis zu welchem ​​Datum?"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'date',
                                                e.target.value,
                                                'noWork',
                                                i
                                            )
                                        }
                                    />
                                </div>
                                <InputRow
                                    className="form-row"
                                    label="Name und Adresse des Arztes"
                                    name={`noWorkDoctorAndAddress${i}`}
                                    placeholder="Name und Anschrift des behandelnden Arztes"
                                    onChange={e =>
                                        handleNestedContentChange(
                                            'doctorNameAndAddress',
                                            e.target.value,
                                            'noWork',
                                            i
                                        )
                                    }
                                />
                                <div className="form-row">
                                    <span className="block-center">
                                        Ist die Behandlung ohne Folgen
                                        abgeschlossen und geheilt?
                                    </span>
                                    <div className="default-radio-container">
                                        <label>
                                            <input
                                                type="radio"
                                                name={`noWorkConcluded${i}`}
                                                onChange={e =>
                                                    handleNestedContentChange(
                                                        'isConcluded',
                                                        e.target.checked,
                                                        'noWork',
                                                        i
                                                    )
                                                }
                                            />
                                            Ja
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name={`noWorkConcluded${i}`}
                                                onChange={e =>
                                                    handleNestedContentChange(
                                                        'isConcluded',
                                                        !e.target.checked,
                                                        'noWork',
                                                        i
                                                    )
                                                }
                                            />
                                            Nein
                                        </label>
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                        {form.noWork.content.length < 3 && (
                            <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                    handleToggleNestedContent('noWork')
                                }
                            >
                                Zusätzliche Krankheit/Verletzung/Operation
                                hinzufügen
                            </button>
                        )}
                    </>
                )}
            </section>
            <section>
                <BooleanRadioOptions
                    title="Wurden Ihnen in den letzten 3 Jahren für mehr als 14 Tage Medikamente verschrieben?"
                    option="medications"
                    onChange={isChecked =>
                        handleFormChange('value', isChecked, 'medications')
                    }
                />
                {form.medications.value && (
                    <>
                        {form.medications.content.map(({ id }, i) => (
                            <Fragment key={id || i}>
                                {i !== 0 && (
                                    <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                            handleToggleNestedContent(
                                                'medications',
                                                i
                                            )
                                        }
                                    >
                                        <CgCloseO />
                                    </button>
                                )}
                                <div className="form-row is-double">
                                    <InputRow
                                        label="Arzneimittel"
                                        name={`medicationName${i}`}
                                        placeholder="Name des eingenommenen Medikaments"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'name',
                                                e.target.value,
                                                'medications',
                                                i
                                            )
                                        }
                                    />
                                    <InputRow
                                        label="Datum"
                                        name={`medicationDate${i}`}
                                        placeholder="Von wann bis zu welchem ​​Datum?"
                                        onChange={e =>
                                            handleNestedContentChange(
                                                'date',
                                                e.target.value,
                                                'medications',
                                                i
                                            )
                                        }
                                    />
                                </div>
                                <InputRow
                                    className="form-row"
                                    label="Name und Adresse des Arztes"
                                    name={`medicationDoctorAndAddress${i}`}
                                    placeholder="Name und Anschrift des behandelnden Arztes"
                                    onChange={e =>
                                        handleNestedContentChange(
                                            'doctorNameAndAddress',
                                            e.target.value,
                                            'medications',
                                            i
                                        )
                                    }
                                />
                            </Fragment>
                        ))}
                        {form.medications.content.length < 3 && (
                            <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                    handleToggleNestedContent('medications')
                                }
                            >
                                Füge weitere Medikamente hinzu
                            </button>
                        )}
                    </>
                )}
            </section>
            <section>
                <p>
                    Welcher Arzt ist am besten über Ihren Gesundheitszustand
                    informiert?
                </p>
                <InputRow
                    className="form-row"
                    label="Name und Adresse des Arztes"
                    placeholder="Geben Sie den vollständigen Namen und die Adresse des Arztes an"
                    name="doctorNameAndAddress"
                    onChange={e =>
                        handleFormChange('doctorNameAndAddress', e.target.value)
                    }
                />
            </section>
            <section>
                <div className="form-row">
                    <label className="form-label" htmlFor="remarks">
                        Bemerkungen
                    </label>
                    <textarea
                        className="form-textarea"
                        name="remarks"
                        id="remarks"
                        rows="5"
                        onChange={e =>
                            handleFormChange('remarks', e.target.value)
                        }
                    ></textarea>
                </div>
                <button
                    type="button"
                    className="btn"
                    onClick={handleSubmit}
                    style={{
                        background: '#1CC691',
                        borderColor: '#1CC691',
                    }}
                >
                    Entdecken Sie die beste Lösung
                </button>
            </section>
        </>
    );

    return (
        <Wrapper>
            <Helmet>
                <title>{metaTitle}</title>
                <meta property="og:title" content={metaTitle} />
                <meta name="robots" content="noindex" />
            </Helmet>
            <form className="container">
                {appLoading ? (
                    <section className="full-width-section">
                        <Loading center />
                    </section>
                ) : (
                    formContent
                )}
            </form>
        </Wrapper>
    );
};

const InputRow = ({
    label,
    name,
    type,
    placeholder,
    onChange,
    value,
    maxLength,
    className,
}) => (
    <div className={className}>
        <label className="form-label" htmlFor={name}>
            {label}
        </label>
        <input
            type={type || 'text'}
            className="form-input"
            id={name}
            maxLength={maxLength}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
        />
    </div>
);

const RadioOption = ({ label, id, name, onChange }) => (
    <>
        <input
            type="radio"
            id={id}
            name={name}
            onChange={e => onChange(e.target.checked)}
        />
        <label className="form-radio-label" htmlFor={id}>
            {label}
        </label>
    </>
);

const BooleanRadioOptions = ({ title, option, onChange }) => (
    <>
        <p>{title}</p>
        <div className="form-row is-double is-small">
            <RadioOption
                id={`is-${option}`}
                name={`is-${option}`}
                label="Ja"
                onChange={isChecked => onChange(isChecked)}
            />
            <RadioOption
                id={`not-${option}`}
                name={`is-${option}`}
                label="Nein"
                onChange={isChecked => onChange(!isChecked)}
            />
        </div>
    </>
);

export default FinalForm;
